import { createAsyncThunk } from '@reduxjs/toolkit';

import { post } from './http';
import { activeSnack } from '../store/common';

export const getAllPortfolioAnalytics = createAsyncThunk(
  'analytics/portfolio/list',
  async ({ portfolioCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/portfolio?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;

      const response = await post(URL, portfolioCredentials);

      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCampaignAnalytics = createAsyncThunk(
  'analytics/campaign/list',
  async ({ campaignCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/campaign?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, campaignCredentials);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllAdGroupAnalytics = createAsyncThunk(
  'analytics/adGroup/list',
  async ({ adGroupCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/ad-group?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, adGroupCredentials);

      console.log(response);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTargetAnalytics = createAsyncThunk(
  'analytics/target/list',
  async ({ targetCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/target?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, targetCredentials);
      console.log(response);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllNegativeTargetAnalytics = createAsyncThunk(
  'analytics/negative-target/list',
  async ({ negativeTargetCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/negative-target?page=${query?.page || ''}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;

      const response = await post(URL, negativeTargetCredentials);
      console.log(response);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSearchTermAnalytics = createAsyncThunk(
  'analytics/search-term/list',
  async ({ searchTermCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/search-term?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;

      const response = await post(URL, searchTermCredentials);
      console.log(response);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProductsAnalytics = createAsyncThunk(
  'analytics/product/list',
  async ({ productCredentials, query }) => {
    try {
      const URL = `analytics/advertiser/product?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;

      const response = await post(URL, productCredentials);
      console.log(response);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
